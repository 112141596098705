import React, { FC } from 'react';
import { Link, graphql } from 'gatsby';

import { IPainHomeBannerComponentProps } from './model';
import './PainHomeBannerSub.scss';
import DangerouslySetInnerHtml from '../common/DangerouslySetInnerHtml';
import UniversalImage from '../common/UniversalImage';
import useScreenRecognition from '../../hooks/useScreenRecognition';

const PainHomeBannerSub: FC<IPainHomeBannerComponentProps> = ({
  painHomeBannerTitleRteSub,
  painHomeBannerBackgroundImageSub,
  painHomeBannerTextSub,
  painHomeBannerHeroImageSub,
  painHomeBannerLinkSub,
}) => {
  const { isMobile } = useScreenRecognition({ isMobile: true });

  return (
    <div
      className="pain-home-banner-sub"
      style={{
        zIndex: 1,
        backgroundImage: `url(${painHomeBannerBackgroundImageSub.fallbackUrl})`,
      }}
    >
      <div className="container-fluid">
        <div className="pain-home-banner-sub__content">
          <div className="pain-home-banner-sub__top-content">
            <DangerouslySetInnerHtml
              className="pain-home-banner-sub__heading"
              element="div"
              html={painHomeBannerTitleRteSub}
            />
          </div>
          <div className="pain-home-banner-sub__bottom-content">
            <Link
              className="pain-home-banner-sub__link"
              to={painHomeBannerLinkSub[0].properties.link[0].url}
            >
              {painHomeBannerLinkSub[0].properties.text}
            </Link>
            <div className="pain-home-banner-sub__text">
              <DangerouslySetInnerHtml html={painHomeBannerTextSub} />
            </div>
          </div>
        </div>
        <UniversalImage
          wrapperClassName="pain-home-banner-sub__hero-image"
          objectFitData={{ objectFit: 'cover' }}
          img={painHomeBannerHeroImageSub}
        />
      </div>
    </div>
  );
};

export const query = graphql`
  fragment FragmentPainHomeBannerSub on IPainHomeBannerSub {
    painHomeBannerTitleRteSub
    painHomeBannerBackgroundImageSub {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    painHomeBannerHeroImageSub {
      fallbackUrl
      gatsbyImage {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    painHomeBannerTextSub
    painHomeBannerLinkSub {
      properties {
        ...FragmentNFButton
      }
    }
  }
`;

export default PainHomeBannerSub;
